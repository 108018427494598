import React from 'react';
import tw from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { capitalizeFirstLetter } from '@utils/helpers';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';

import LayoutConcrete from '@layouts/concrete';
import SEO from '@components/seo.jsx';
import Text from '@components/text';
import BlockSanity from '@components/block-sanity';

const Container = tw.div`
  relative z-1 max-w-xs mx-auto px-10 pt-50 text-center 
  sm:max-w-480
  lg:max-w-full lg:mb-50 lg:px-0 lg:pt-0 lg:text-left
`;

const Title = tw(Text)`mb-15 lg:mb-44`;

const PrivacyPolicy = () => {
  const intl = useIntl();
  const { sanityPrivacyPolicy } = useStaticQuery(graphql`
    query {
      sanityPrivacyPolicy(name: {eq: "Polityka prywatności"}) {
        _rawPrivacyEn
        _rawPrivacyPl
      }
    }
  `);

  return (
    <LayoutConcrete>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'meta tags.title.privacy policy' })}
      />
      <Container>
        <Title
          color="black"
          fontFamily="bebasNeue"
          fontSizes={[42, 56]}
          fontWeight={600}
          lineHeight={1.15}
          letterSpacing={[53, 70]}
        >
          <FormattedMessage id="privacy policy.title" />
        </Title>
        <Text
          fontSizes={16}
          fontWeight={300}
          lineHeight={1.5}
          letterSpacing={20}
        >
          <BlockSanity content={sanityPrivacyPolicy[`_rawPrivacy${capitalizeFirstLetter(intl.locale)}`]} />
        </Text>
      </Container>
    </LayoutConcrete>
  );
};

export default PrivacyPolicy;
